<template>
  <ion-tab-bar slot="bottom" mode="ios" v-if="!hideTabNavigation">
    <ion-fab style="margin-bottom: 30px" horizontal="center" translucent="true">
      <ion-fab-button @click="() => router.push('/menu')">
        <ion-label class="order-button">ORDER</ion-label>
      </ion-fab-button>
    </ion-fab>

    <ion-tab-button tab="home" :class="{ active: isActiveRoute('/') }" @click="() => router.push('/')">
      <ion-icon :icon="isActiveRoute('/') ? home : homeOutline" class="nav-color"></ion-icon>
      <ion-label class="nav-color">HOME</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="offers" :class="{ active: isActiveRoute('/offers') }" @click="() => router.push('/offers')">
      <ion-icon :icon="isActiveRoute('/offers') ? pricetags : pricetagsOutline" class="nav-color"></ion-icon>
      <ion-label class="nav-color">DEALS</ion-label>
    </ion-tab-button>

    <!-- Tab Spacer  -->
    <svg height="50" viewBox="0 0 100 50" width="100" xmlns="http://www.w3.org/2000/svg"></svg>

    <ion-tab-button tab="rewards" :class="{ active: isActiveRoute('/rewards') }" @click="() => router.push('/rewards')">
      <ion-icon :icon="isActiveRoute('/rewards') ? gift : giftOutline" class="nav-color"></ion-icon>
      <ion-label class="nav-color">REWARDS</ion-label>
    </ion-tab-button>

    <ion-tab-button
      tab="Account"
      :class="{ active: isActiveRoute('/login') }"
      @click="() => (isLoggedIn ? (isAccountModalOpen = true) : router.replace('/login'))"
    >
      <ion-icon :icon="isActiveRoute('/login') ? person : personOutline" class="nav-color"></ion-icon>
      <ion-label class="nav-color">ACCOUNT</ion-label>
    </ion-tab-button>
    <accountModal v-if="isAccountModalOpen" justModal @close="delayAccountModalClose" />
  </ion-tab-bar>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "vue";
import { IonIcon, IonLabel, IonTabBar, IonTabButton, IonFab, IonFabButton } from "@ionic/vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { home, homeOutline, pricetags, pricetagsOutline, person, personOutline, gift, giftOutline } from "ionicons/icons";
import accountModal from "@/components/modals/accountModal.vue";

export default defineComponent({
  components: {
    IonLabel,
    IonTabButton,
    IonTabBar,
    IonIcon,
    IonFab,
    IonFabButton,
    accountModal,
  },
  methods: {
    isActiveRoute(path: string): boolean {
      return this.router.currentRoute.value.path == path;
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const isNative = computed(() => store.getters.isNative);
    const isLoggedIn = computed(() => store.getters.isLoggedIn);
    const isAccountModalOpen = ref();
    const hideTabNavigation = computed(() => router.currentRoute.value.meta?.hideTabNavigation);

    const delayAccountModalClose = () => setTimeout(() => (isAccountModalOpen.value = false), 200);

    return {
      router,
      isNative,
      home,
      homeOutline,
      pricetags,
      pricetagsOutline,
      person,
      personOutline,
      gift,
      giftOutline,
      isAccountModalOpen,
      isLoggedIn,
      delayAccountModalClose,
      hideTabNavigation,
    };
  },
});
</script>

<style scoped>
.order-button {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-light);
  font-weight: bold;
  font-size: 0.9em;
}
ion-tabs {
  contain: none;
}
ion-tab-bar {
  contain: none;
}
svg {
  width: 72px;
  margin-top: 19px;
}
svg > path {
  fill: var(--ion-color-light);
}
ion-icon {
  font-size: 2.3em;
}
.active {
  color: var(--ion-color-primary);
}
.nav-color {
  color: #707070;
}
</style>