<template>
  <div>
    <p v-if="(!addresses || addresses.length == 0) && !showForm" data-cy="no-address-msg" class="ion-margin ion-text-center">
      You have no addresses saved. <br />
      Select Add Address to add an address and checkout even faster.
    </p>

        <ion-list class="py-0" data-cy="address-list" v-if="addresses && showList">
            <ion-radio-group :value="selectedIndex" @ionChange="selectAddress($event.detail.value)" mode="md">
                <ion-item v-for="(address, i) in addresses" :key="address.addressId ?? i" :button="selectable" class="address-item" lines="full">
                    <ion-radio v-if="selectable" slot="start" :value="i" class="address-radio" :data-cy="'radio-address-' + i"></ion-radio>
                    <ion-row class="full-width">
                        <div class="full-width">
                            <ion-row v-if="address.default">
                                <span data-cy="default" class="small">Default Address</span>
                            </ion-row>
                            <ion-row class="my-4 ion-text-capitalize">
                                <strong>{{ address.name }}</strong>
                            </ion-row>
                            <ion-row class="my-4">
                                <ion-col size="7" class="pa-0">
                                    <addressDisplay :address="address.address" dense />
                                </ion-col>
                                <ion-col size="3" v-if="selectedIndex === i || !selectable" class="ion-text-end">
                                    <ion-button
                                        v-if="editable"
                                        small
                                        style="width: unset"
                                        fill="clear"
                                        color="primary"
                                        class="my-0 mr-4"
                                        data-cy="button-address-edit"
                                        @click="editAddress(address)"
                                    >
                                        Edit
                                    </ion-button>
                                    <ion-button
                                        v-if="deleteable"
                                        small
                                        style="width: unset"
                                        fill="clear"
                                        color="primary"
                                        class="my-0"
                                        @click="deleteAddress(address)"
                                        data-cy="button-address-delete"
                                    >
                                        Remove
                                    </ion-button>
                                </ion-col>
                            </ion-row>
                        </div>
                    </ion-row>
                </ion-item>
            </ion-radio-group>
        </ion-list>


        <ion-row class="mx-auto w-full pt-8">
        <ion-button v-if="addBtn && showList" @click="newAddress()" class="mx-auto">Add a New Address</ion-button>
      </ion-row>

    <button v-if="showForm" fill="clear" class="back-button mx-auto" @click="toggleForm()">
      <ion-icon :icon="arrowBackOutline"></ion-icon>
    </button>

    <addressForm
      v-if="showForm"
      :submit-function="saveFunction"
      :initial-values="formAddress"
      :submission-error="submissionError"
      showname
      show-default-check
      submit-btn-text="Save"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, PropType, ref } from "vue";
import { IonButton, IonItem, IonList, IonRadioGroup, IonRadio, IonRow, IonCol, IonIcon } from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";
import addressDisplay from "@/components/addressDisplay.vue";
import addressForm from "./forms/addressForm.vue";
import { pencilOutline, trashOutline } from "ionicons/icons";
import { CustomerAddress, DeliveryAddress } from "@/models/addressModels";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    addresses: {
      type: Array as PropType<DeliveryAddress[]>,
      default: () => [],
    },
    selectable: Boolean,
    editable: Boolean,
    deleteable: Boolean,
    addBtn: Boolean,
  },
  emits: ["selected", "updateShowAddressForm"],
  setup(props, { emit }) {
    const store = useStore();
    const submissionError = ref(undefined);
    //@ts-ignore
    const defaultIndex = props.addresses.findIndex((a) => a.isFavorite);
    const selectedIndex = ref(defaultIndex);

    const deleteAddress = (address) => {
      store.dispatch("deleteUserAddress", address);
      // Address is only deletable if it's been selected, required to select a new one.
      if (props.selectable) {
        nextTick(() => {
          selectedIndex.value = 0;
          emit("selected", props.addresses[selectedIndex.value]);
        });
      }
    };

    const selectAddress = (i) => {
      selectedIndex.value = i;
      if (props.selectable) emit("selected", props.addresses[selectedIndex.value]);
    };

    if (props.addresses[selectedIndex.value] && props.selectable) {
      emit("selected", props.addresses[selectedIndex.value]);
    }

    const showList = ref(true);
    const showForm = ref(false);
    const formAddress = ref(new CustomerAddress({}));

    const toggleForm = () => {
      showList.value = !showList.value;
      showForm.value = !showForm.value;
      emit("updateShowAddressForm", showForm.value);
      submissionError.value = undefined;
    };

    const editAddress = (address) => {
      formAddress.value = address;
      toggleForm();
      saveFunction.value = updateExistingAddress;
    };

    const newAddress = () => {
      formAddress.value = new CustomerAddress({});
      saveFunction.value = saveNewAddress;
      toggleForm();
    };

    const updateExistingAddress = async (payload) => {
      store
        .dispatch("editUserAddress", payload)
        .then(() => {
          toggleForm();
        })
        .catch((err) => (submissionError.value = err));
    };

    const saveNewAddress = async (payload) => {
      store
        .dispatch("addUserAddress", payload)
        .then(() => {
          toggleForm();
          // if successfull change selection to last address
          if (props.selectable) selectedIndex.value = props.addresses.length - 1;
        })
        .catch((err) => (submissionError.value = err));
    };

    const saveFunction = ref(saveNewAddress);

    return {
      deleteAddress,
      pencilOutline,
      trashOutline,
      selectedIndex,
      selectAddress,
      showList,
      showForm,
      toggleForm,
      CustomerAddress,
      saveNewAddress,
      newAddress,
      arrowBackOutline,
      formAddress,
      editAddress,
      saveFunction,
      submissionError,
    };
  },
  components: {
    IonList,
    IonItem,
    IonButton,
    IonRow,
    IonRadioGroup,
    IonRadio,
    IonCol,
    IonIcon,
    addressDisplay,
    addressForm,
  },
});
</script>

<style scoped>
.address-radio {
  margin-inline: 8px;
  margin-bottom: 8px;
  align-self: center;
}

ion-item {
  --inner-padding-top: 8px !important;
}

ion-label {
  padding-bottom: 8px;
}

ion-button {
  width: 100%;
}

ion-icon {
  font-size: xx-large;
  margin-left: 0.5em;
  color: var(--ion-color-primary);
  text-align: left;
}

.back-button {
  text-align: left;
  background: transparent;
}

@media screen and (min-width: 960px) {
  ion-button {
    width: auto;
    margin-right: auto;
    margin-left: auto;
    align-self: center;
    justify-self: center;
  }
}
</style>
