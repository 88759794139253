<template>
  <modalWrapper
    mode="ios"
    :title="title"
    :open="modalVisible"
    @close="close"
    @dismiss="close"
    maxHeight="350px"
    height="350px"
    maxWidth="450px"
    minHeight="350px"
  >
    <div class="body-container">
      <div class="modal-body">
        <h5 class="pb-8">{{ header }}</h5>
        <p>{{ message }}</p>
      </div>
      <div class="response-modal-button">
        <ion-button @click="handleAccept">{{ confirmationMessage }}</ion-button>
        <ion-button v-if="declineMessage" @click="handleDecline">{{ declineMessage }}</ion-button>
      </div>
    </div>
  </modalWrapper>
</template>

<script lang="ts">
import modalWrapper from "./modalWrapper.vue";
import { IonButton } from "@ionic/vue";

export default {
  props: {
    title: String,
    header: String,
    message: String,
    confirmationMessage: { Type: String, Default: "Okay" },
    declineMessage: String,
    modalVisible: Boolean,
    close: Function,
  },
  components: {
    modalWrapper,
    IonButton,
  },
  emits: ["accept", "decline"],
  setup(props, { emit }) {
    const handleAccept = () => {
      emit("accept");
      props.close();
    };

    const handleDecline = () => {
      emit("decline");
      props.close();
    };

    return { handleAccept, handleDecline };
  },
};
</script>

<style scoped>
.modal-body {
  padding: 1em;
}

.body-container {
  display: flex;
  flex-direction: column;
  text-align: center;
}

ion-modal {
  --min-height: 165px;
  --min-width: 40vw;
  --max-width: 500px;
}

.response-modal-button {
  text-align: center;
  padding-right: 1em;
}
ion-modal {
  --backdrop-opacity: 0.7 !important;
}
</style>
