<template>
  <modalWrapper maxHeight="580px" minHeight="580px" title="Credit Card" :open="modalOpen" mode="ios" @close="closeModal()" @dismiss="closeModal()">
    <template v-slot>
      <!-- card type image list -->
      <ion-row class="ion-justify-content-center">
        <ion-col size="10" style="display: flex; align-items: center; margin-bottom: -10px">
          <payment-type-image v-for="cardType in acceptedCardTypes" :key="cardType" class="small" :type="cardType" />
        </ion-col>
      </ion-row>

      <!-- CC Form -->
      <ion-row class="ion-justify-content-center">
        <ion-col size="10">
          <p class="interstate-bold error-message" v-if="gatewayError">{{ gatewayError }}</p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="10" class="pl-20">
          <ion-text color="primary">{{ cardNumberError }}</ion-text>
            <text-input
            label="Card Number"
            placeholder="Card Number"
            v-model="cardNumber"
            type="number"
            autocomplete="cc-number"
            required
            mode="md"
            />
        </ion-col>
        <ion-col size="7" class="pl-20">
            <text-input
            label="Expiration Date"
            placeholder="MMYY"
            v-model="expirationDate"
            type="number"
            autocomplete="cc-exp"
            required
            mode="md"
            />
        </ion-col>
        <ion-col size="3" class="pl-20">
          <text-input
          label="CVV2"
          placeholder="CVV2"
          v-model="cvv"
          type="number"
          autocomplete="cc-csc"
          required
          mode="md"
          />
        </ion-col>
        <ion-col size="3" class="pl-20">
          <text-input
          label="Zip"
          placeholder="zipCode"
          v-model="zipCode"
          type="number"
          autocomplete="cc-zip"
          required
          mode="md"
          />
        </ion-col>
        <ion-col size="7"> </ion-col>
        <ion-col size="11" v-if="isLoggedIn && !hideCheckBox">
            <ion-checkbox v-model="saveCardCheckbox" color="success" label-placement="end" class="sel-option">Save Card</ion-checkbox>
        </ion-col>
        <ion-col size="11" v-if="isLoggedIn && !hideCheckBox && saveCardCheckbox">
            <ion-checkbox v-model="defaultCardCheckbox" color="success" label-placement="end" class="sel-option">Make This My Default Card</ion-checkbox>
        </ion-col>
        <ion-col size="11" class="pl-20">
          <ion-text color="primary">{{ expirationDateError }}</ion-text> <br />
          <ion-text color="primary">{{ cvvError }}</ion-text> <br />
          <ion-text color="primary">{{ zipCodeError }}</ion-text>
        </ion-col>
      </ion-row>

      <ion-row class="ion-justify-content-center">
        <ion-col size="10" class="d-flex ion-justify-content-center">
          <ion-spinner v-if="isCardProcessing" name="lines-small" />
        </ion-col>
      </ion-row>
    </template>

    <template v-slot:footer>
      <ion-button color="primary" expand="full" class="force-bottom full-width ma-0" size="large" @click="handleSubmit"> Add Credit Card </ion-button>
    </template>
  </modalWrapper>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from "vue";
import { IonButton, IonSpinner, IonRow, IonCol, IonText, IonCheckbox } from "@ionic/vue";

import { object, string } from "yup";
import { useField, useForm } from "vee-validate";

import modalWrapper from "@/components/modals/modalWrapper.vue";
import useCheckoutJS from "@/composables/useCheckoutJS";
import { ElavonResponse } from "@/models/PaymentModels";
import paymentTypeImage from "../paymentTypeImage.vue";
import { cardTypeEnum } from "@/enums/PaymentTypeEnum";
// @ts-ignore
import { useReCaptcha } from "vue-recaptcha-v3";
import { useStore } from "vuex";
import TextInput from "@/components/inputs/textInput.vue";

export const creditCardSchema = object({
  cardNumber: string().required("Credit card number is required."),
  expirationDate: string()
    .required("Expiration date is required.")
    .min(4, "Expiration must be exactly 4 digits")
    .max(4, "Expiration must be exactly 4 digits"),
  cvv: string().required("CVV is required.").min(3, "CVV must be at least 3 characters").max(5, "CVV must be no more than 5 characters"),
  zipCode: string().required("Zipcode is required.").min(5, "Please enter a valid Zipcode").max(5, "Please enter a valid Zipcode"),
}).required();

export default defineComponent({
  props: { modalOpen: Boolean, hideCheckBox: Boolean },
  components: { TextInput, IonSpinner, IonButton, IonRow, IonCol, IonText, modalWrapper, paymentTypeImage, IonCheckbox },
  emits: ["modalOpen", "addCreditCard"],
  setup(props, { emit }) {
    const store = useStore();

    const { getTokenizedPayment, paymentGatewaySuccess, paymentGatewayError } = useCheckoutJS();
    //@ts-ignore
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
    const isCardProcessing = ref(false);
    const gatewayError = ref("");
    const saveCardCheckbox = ref(false);
    const defaultCardCheckbox = ref(false);
    const validatingCard = ref(false);
    const isLoggedIn = computed(() => store.getters.isLoggedIn);

    const { values, validate } = useForm({ validationSchema: creditCardSchema });
    const { value: cardNumber, errorMessage: cardNumberError } = useField("cardNumber");
    const { value: expirationDate, errorMessage: expirationDateError } = useField("expirationDate");
    const { value: cvv, errorMessage: cvvError } = useField("cvv");
    const { value: zipCode, errorMessage: zipCodeError } = useField("zipCode");

    const acceptedCardTypes = [cardTypeEnum.visa, cardTypeEnum.masterCard, cardTypeEnum.discover, cardTypeEnum.amex];

    watch(paymentGatewaySuccess, (response: ElavonResponse) => {
      isCardProcessing.value = false;

      if (response.validElavonResponse) {
        gatewayError.value = "";
        emit("addCreditCard", {
          creditCard: response.convertToCard(),
          saveCard: saveCardCheckbox.value,
          defaultCreditCard: defaultCardCheckbox.value,
        });
        emit("modalOpen", false);
      } else {
        const avsError = "Declined - AVS Mismatch.";
        if (!paymentGatewayError.value) {
          gatewayError.value = avsError;
        }
      }
    });

    watch(paymentGatewayError, (error) => {
      isCardProcessing.value = false;
      if (error === undefined) {
        gatewayError.value = "Card declined, please try again.";
      } else {
        gatewayError.value = error;
      }
    });

    const closeModal = () => {
      emit("modalOpen", false);
      gatewayError.value = "";
      cardNumber.value == "";
      cvv.value == "";
      zipCode.value == "";
      expirationDate.value == "";
    };

    const recaptchaToken = ref("");
    const recaptcha = async () => {
      await recaptchaLoaded();
      // Execute reCAPTCHA with action "paymentValidation".
      recaptchaToken.value = await executeRecaptcha("paymentValidation");
    };

    const handleSubmit = () => {
      validate().then(async (form) => {
        if (!form.valid) return;
        recaptcha().then(() => {
          isCardProcessing.value = true;
          getTokenizedPayment(
            { cardNumber: cardNumber.value, cvv: cvv.value, expirationDate: expirationDate.value, zipCode: zipCode.value },
            recaptchaToken.value
          );
        });
      });
      recaptchaToken.value = "";
    };

    return {
      closeModal,
      handleSubmit,
      validatingCard,
      isCardProcessing,
      gatewayError,
      cardNumber,
      cardNumberError,
      expirationDate,
      expirationDateError,
      zipCode,
      zipCodeError,
      cvv,
      cvvError,
      values,
      acceptedCardTypes,
      saveCardCheckbox,
      isLoggedIn,
      defaultCardCheckbox,
    };
  },
});
</script>

<style scoped>
ion-modal {
  --max-width: 500px !important;
  --min-height: 650px;
  --border-radius: 8px !important;
}
ion-button {
  text-transform: unset !important;
}
.force-bottom {
  position: fixed;
}
.error-message {
  color: var(--ion-color-primary);
  font-size: 1em;
}

ion-modal {
  --backdrop-opacity: 0.7 !important;
}

ion-modal ion-input {
    border: 2px solid #dddddd;
    border-radius: 4px;
    padding-right: 16px !important;
    padding-left: 16px !important;
}
.sel-option {
    padding-left: 1rem;
    font-family: "Roboto", "Helvetica Neue", sans-serif;
}
</style>
