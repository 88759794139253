<template>
  <p class="ion-text-capitalize bold color-dark inline-list-p mt-4" v-if="items && items.length > 0">
    <span
      v-for="item in items"
      :key="item[keyName]"
      class="inline-list-item small"
      v-html="displayFunc ? displayFunc(item) : item[keyName]?.trim()"
    />
  </p>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: { items: Array, keyName: String, displayFunc: Function },
});
</script>
<style scoped>
.p {
  line-height: 1;
}
.inline-list-item:not(:last-child)::after {
  content: ", ";
}
</style>

<style>
.inline-list-p > span > img {
  max-height: 12px !important;
  max-width: 12px !important;
}
</style>
