<template>
  <ion-modal
    :is-open="open"
    @didDismiss="$emit('close', $event)"
    :canDismiss="canDismiss"
    :backdropDismiss="backdropDismiss"
    :style="{ '--min-width': minWidth, '--min-height': minHeight, '--max-height': maxHeight, '--max-width': maxWidth }"
  >

    <ion-content>
        <ion-header>
            <ion-toolbar class="modal-toolbar" v-if="!hideToolbar">
                <ion-button v-if="canDismiss" slot="end" @click="$emit('close', $event)" fill="clear">
                    <ion-icon slot="icon-only" :color="isHeaderActive ? 'light' : 'medium'" :icon="closeOutline" />
                </ion-button>
                <ion-title mode="ios" :class="{ active: isHeaderActive }" v-html="title" />
            </ion-toolbar>
        </ion-header>
      <slot />
    </ion-content>

    <ion-footer mode="ios">
      <slot name="footer" />
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { IonContent, IonModal, IonTitle, IonIcon, IonToolbar, IonButton, IonFooter, IonHeader } from "@ionic/vue";
import { closeOutline } from "ionicons/icons";

export default defineComponent({
  props: {
    title: String,
    open: Boolean,
    hideToolbar: Boolean,
    isHeaderActive: { default: false },
    minHeight: { default: "85vh" },
    minWidth: { default: "35vw" },
    maxHeight: { default: "unset" },
    maxWidth: { default: "unset" },
    height: { default: "45vh" },
    width: { default: "unset" },
    canDismiss: { default: true },
    backdropDismiss: { default: true },
  },
  components: {
    IonContent,
    IonModal,
    IonTitle,
    IonIcon,
    IonToolbar,
    IonButton,
    IonFooter,
    IonHeader
  },
  emits: ["confirm", "close", "dismiss"],

  setup() {
    const store = useStore();
    const isPortrait = computed(() => {
      return store.getters.isPortraitOrientation;
    });
    return { closeOutline, isPortrait };
  },
});
</script>

<style scoped>

.modal-title {
  padding-left: 56px;
}

.active {
  background: #ad1b29 !important;
  color: #fff;
  text-align: left !important;
  font-size: 22px;
  padding: 20px !important;
  font-weight: 500;
}

@media screen and (min-width: 650px) {
  ion-modal {
    --width: 90vw;
    --height: 45vh;
    --min-width: unset;
    --max-width: 1500px;
  }
}
@media screen and (max-width: 650px) {
  .active {
    font-size: 19px !important;
  }
}
</style>
