<template>
  <form @submit="onSubmit" data-cy="form-address">
    <ion-grid class="ion-padding-horizontal">
      <ion-row>
        <ion-col size="12" v-if="showname">
          <name v-model="nickname" label="Address name" labelColor="medium" :error="nicknameError" placeholder="Office" fill="outline" mode="md" />
        </ion-col>

        <ion-col size="12">
          <text-input
              label="Address"
              fill="outline"
              placeholder="Address"
              v-model="address1"
              required
              autocomplete="address-line1"
              @enter="onSubmit"
              data-cy="input-address1"
              tabindex="1"
              mode="md"
          />
          <ion-note color="danger" data-cy="error-address1">{{ addressError }}</ion-note>
        </ion-col>

        <ion-col size="12">
          <text-input
            label="Apt/Suite/Bldg"
            fill="outline"
            placeholder="Apt 123"
            v-model="address2"
            autocomplete="address-line2"
            @enter="onSubmit"
            data-cy="input-address2"
            autocapitalize="words"
            tabindex="2"
            mode="md"
          />
          <ion-note color="danger" data-cy="error-address2">{{ address2Error }}</ion-note>
        </ion-col>

        <ion-col size="12">
            <text-input
                label="City"
                fill="outline"
                v-model="city"
                required
                autocomplete="address-level2"
                @enter="onSubmit"
                data-cy="input-city"
                autocapitalize="words"
                tabindex="3"
                mode="md"
            />
            <ion-note color="danger" data-cy="error-city">{{ cityError }}</ion-note>
        </ion-col>

        <ion-col size="6" class="ion-padding-end">
            <text-input
                label="State"
                fill="outline"
                placeholder="OH"
                v-model="state"
                required
                autocomplete="address-level1"
                @enter="onSubmit"
                data-cy="input-state"
                autocapitalize="characters"
                tabindex="4"
                mode="md"
            />
            <ion-note color="danger" data-cy="error-state">{{ stateError }}</ion-note>
        </ion-col>
        <ion-col size="6" class="ion-padding-start">
          <zip required autoComplete tabindex="5" v-model="zip" fill="outline" :error="zipError" label="Zip Code" />
        </ion-col>

        <ion-col size="12">
            <text-input
                label="Delivery Instructions"
                fill="outline"
                placeholder="The gate code is 1234#"
                v-model="deliveryInstructions"
                @enter="onSubmit"
                data-cy="input-instructions"
                tabindex="6"
                mode="md"
            />
            <ion-note color="danger" data-cy="error-instructions">{{ instructionsError }}</ion-note>
        </ion-col>

        <ion-col class="ion-margin-bottom" size="12" v-if="showDefaultCheck">
            <ion-checkbox
                label-placement="end"
                v-model="isFavorite"
                data-cy="default-location"
                class="mr-4"
                tabindex="7"
            >Use this as my default address</ion-checkbox>
        </ion-col>

        <ion-col size="12">
          <ion-text v-if="submissionError" style="font-size: 1.3em" color="danger pl-8 ml-4">{{ submissionError }}</ion-text>
          <ion-button
            color="primary"
            expand="block"
            :disabled="submitting"
            :fill="devalueAction ? 'outline' : 'solid'"
            data-cy="button-submit-address"
            tabindex="8"
            type="submit"
          >
            <ion-spinner v-if="loading" name="lines-small" />
            <div v-else>{{ submitBtnText }}</div>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, watch, computed } from "vue";
import { IonGrid, IonButton, IonCheckbox, IonRow, IonText, IonCol, IonNote, IonSpinner } from "@ionic/vue";
import name from "@/components/inputs/name.vue";
import zip from "@/components/inputs/zip.vue";
import { CustomerAddress } from "@/models/addressModels";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import TextInput from "@/components/inputs/textInput.vue";

export default defineComponent({
  props: {
    initialValues: {
      type: Object as PropType<CustomerAddress>,
      required: false,
    },
    submitBtnText: {
      type: String,
      default: "Submit",
    },
    submitFunction: {
      type: Function,
      required: true,
    },
    submissionError: { type: String || undefined },
    showname: Boolean,
    showDefaultCheck: Boolean,
    devalueAction: Boolean,
  },
  components: {TextInput, IonGrid, IonRow, IonCol, IonText, IonCheckbox, IonNote, name, zip, IonButton, IonSpinner },
  emits: ["submit", "updatingAddress"],
  setup(props, { emit }) {
    const store = useStore();
    const submitting = ref(false);
    const loading = ref(false);
    const schema = yup.object({
      addressId: yup.string(),
      nickname: yup.string().label("Address Name").max(45),
      address1: yup.string().required().label("Street address").max(45),
      address2: yup.string().label("Address 2").max(45),
      city: yup.string().required().label("City").max(45),
      state: yup.string().required().label("State").min(2).max(2),
      zip: yup.string().required().label("Zip Code").min(5).max(5),
      deliveryInstructions: yup.string().label("Instructions").max(80),
      isFavorite: yup.boolean(),
    });
    const isMobile = computed(() => store.getters.isMobile);

    const form = useForm({
      validationSchema: schema,
      //@ts-ignore
      initialValues: props.initialValues,
    });
    const { value: nickname, errorMessage: nicknameError } = useField<string | undefined>("nickname");
    const { value: address1, errorMessage: addressError } = useField<string | undefined>("address1");
    const { value: address2, errorMessage: address2Error } = useField<string | undefined>("address2");
    const { value: city, errorMessage: cityError } = useField<string | undefined>("city");
    const { value: state, errorMessage: stateError } = useField<string | undefined>("state");
    const { value: zip, errorMessage: zipError } = useField<string | undefined>("zip");
    const { value: deliveryInstructions, errorMessage: instructionsError } = useField<string | undefined>("deliveryInstructions");
    const { value: isFavorite } = useField<boolean | undefined>("isFavorite");

    const onSubmit = form.handleSubmit((values) => {
      submitting.value = true;
      loading.value = submitting.value;
      values.addressId = props.initialValues?.addressId ?? undefined;
      return props.submitFunction(new CustomerAddress(values)).finally(() => (submitting.value = false));
    });
    watch(
      () => props.submissionError,
      () => {
        loading.value = false;
      }
    );

    watch(
      () => props.initialValues,
      (customerAddress) => {
        form.setValues({
          address1: customerAddress?.address1,
          address2: customerAddress?.address2,
          city: customerAddress?.city,
          state: customerAddress?.state,
          zip: customerAddress?.zip,

          nickname: customerAddress?.nickname,
          deliveryInstructions: customerAddress?.deliveryInstructions,
          isFavorite: customerAddress?.isFavorite,
        });
      }
    );

    watch(
      () => form.values.address1,
      () => emit("updatingAddress")
    );

    return {
      loading,
      onSubmit,
      nickname,
      address1,
      address2,
      city,
      state,
      zip,
      deliveryInstructions,
      isFavorite,
      addressError,
      cityError,
      stateError,
      zipError,
      form,
      submitting,
      isMobile,
      nicknameError,
      address2Error,
      instructionsError,
    };
  },
});
</script>

<style scoped>
ion-col {
  padding-inline: 0;
  margin-bottom: 1em;
}

@media screen and (max-width: 960px) {
  ion-col {
    padding: 0;
  }
}

ion-col.ion-padding-end {
    padding-right: 8px;
}

ion-col.ion-padding-start {
    padding-left: 8px;
}

</style>
