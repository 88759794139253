<template>
  <ion-card-subtitle
    itemprop="address"
    class="address-display"
    itemtype="http://schema.org/PostalAddress"
    :class="{ inline: inline, dense: dense, large: large }"
    v-if="address"
  >
    <span itemprop="streetAddress" data-cy="address1" class="ion-text-capitalize"
      >{{ address.address1 }}{{ address.address2 || !inline ? "" : "," }}&nbsp;</span
    >
    <span v-if="address.address2" data-cy="address2">{{ address.address2 }}{{ address.address2 && inline ? "," : "" }}&nbsp;</span>
    <br v-if="!inline" />
    <span itemprop="addressLocality" data-cy="city" class="ion-text-capitalize">{{ address.city }}</span>
    <span>, </span>
    <span itemprop="addressRegion" data-cy="state" class="ion-text-uppercase">{{ address.state }}</span>
    <span v-if="showZip" itemprop="postalCode" data-cy="zipcode">&nbsp;{{ address.zip ?? address.zipcode }}</span>
  </ion-card-subtitle>

  <ion-card-subtitle :class="{ inline: inline }" v-else>
    <dynamicSkeleText element="span" :childElements="skeleText" animate />
  </ion-card-subtitle>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IonCardSubtitle } from "@ionic/vue";
import dynamicSkeleText from "@/components/dynamicSkeleText.vue";
import { skeleText } from "@/models/props/skeleText";
import { Address } from "@/models/addressModels";

export default defineComponent({
  props: { address: { type: Object as PropType<Address> }, inline: Boolean, dense: Boolean, showZip: Boolean, large: Boolean },
  components: { IonCardSubtitle, dynamicSkeleText },
  setup(props) {
    const skeleText: skeleText[] = [
      { width: 60 },
      { width: 20, display: "inline-block", marginRight: "5px" },
      { width: 5, display: "inline-block", marginRight: "5px" },
      { width: 15, display: "inline-block", marginRight: "5px" },
    ];
    if (props.inline) skeleText.shift();

    return { skeleText };
  },
});
</script>

<style scoped>
ion-card-subtitle {
  line-height: 1.8;
}
.dense {
  line-height: 1.5;
}
.address-display:not(.large) > span {
  font-size: 16px;
}
</style>
