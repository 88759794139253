<template>
  <ion-header role="navigation" aria-label="native app navigation" class="ion-no-border py-0 px-0">
    <ion-toolbar mode="ios">
      <ion-buttons slot="start">
        <ion-back-button v-if="backBtn" :default-href="prevRoute" mode="ios" :text="backText" />
      </ion-buttons>

      <ion-buttons slot="end" style="padding: 10px 10px">
        <cartBtn size="large" color="medium" />
      </ion-buttons>

      <ion-title class="ion-text-center" :router-link="titleRoute">
        <ion-img alt="Donatos Logo" class="menu-logo" :src="require('@/assets/logo-dark.png')" />
      </ion-title>
    </ion-toolbar>
  </ion-header>
</template>

<script lang="ts">
import { defineComponent, computed, } from "vue";
import { useRouter } from "vue-router";
import { IonHeader, IonToolbar, IonButtons, IonTitle, IonImg, IonBackButton } from "@ionic/vue";
import { homeOutline } from "ionicons/icons";
import CartBtn from "@/components/cartBtn.vue";

export default defineComponent({
  components: { IonHeader, IonToolbar, IonButtons, CartBtn, IonTitle, IonImg, IonBackButton },
  props: {},
  computed: {
    prevRoute() {
      const arr = this.$router.currentRoute.value.path.split("/");
      return arr.splice(0, arr.length - 1).join("/");
    },
    titleRoute() {
      return "/";
      // 08/10/22
      // setting the logo on native to route /home for now
      // keeping the old routing commented, incase we need to swtich it back.
      // return this.$route.meta?.orderingRoute ? "/menu" : "/location";
    },
  },
  setup() {
    const router = useRouter();
    const backBtn = computed(() => router.currentRoute.value.meta?.backBtn);
    const backText = computed(() => router.currentRoute.value.meta?.backText);
    return { homeOutline, backBtn, backText };
  },
});
</script>

<style scoped>
.menu-logo {
  max-width: 150px;
  margin: 0 auto;
}
</style>
