<template>
  <ion-menu class="nav-menu" content-id="main-content" type="overlay" data-cy="mobile-nav-drawer">
    <!-- Nav Header -->
    <ion-header aria-label="mobile navigation" class="ion-no-border mobile-nav-header" data-cy="mobile-nav-header">
      <ion-toolbar mode="md">
        <ion-row size="12" style="margin-bottom: -35px">
          <ion-col class="ion-align-self-end" size="1" offset="10">
            <ion-menu-toggle style="cursor: pointer">
              <ion-icon style="font-size: 25px" :ios="close" :md="close" data-cy="mobile-nav-drawer-close" />
            </ion-menu-toggle>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center" size="12">
          <ion-col size="8" offset="1">
            <ion-menu-toggle>
              <router-link to="/">
                <ion-img alt="Donatos Logo" class="menu-logo" :src="require('@/assets/logo-dark.png')" data-cy="mobile-nav-drawer-logo" />
              </router-link>
            </ion-menu-toggle>
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-header>

    <ion-content :scroll-y="true" :force-overscroll="false">
      <!-- Logged In Header -->
      <div class="logged-in-header" v-if="isLoggedIn">
        <ion-row size="12">
          <ion-col class="welcome" size="10" offset="1" data-cy="welcome-back-message">
            Welcome back, <span class="first-name">{{ firstName }}</span
            >!
          </ion-col>
        </ion-row>
        <ion-menu-toggle>
          <ion-row v-if="account.cheetahMemberRewards" @click="() => router.replace('/rewards')" size="12">
            <ion-col class="account-rewards" style="cursor: pointer" size="10" offset="1" data-cy="check-rewards-cta">
              Check your rewards
              <ion-icon
                style="vertical-align: middle; font-size: 14px; margin-left: -5px"
                :ios="chevronForward"
                :md="chevronForward"
                color="primary"
              />
              <ion-icon
                style="vertical-align: middle; font-size: 14px; margin-left: -9px"
                :ios="chevronForward"
                :md="chevronForward"
                color="primary"
              />
            </ion-col>
          </ion-row>
        </ion-menu-toggle>
        <div class="list-divider"></div>
      </div>

      <!-- Navigation Menu -->
      <ion-menu-toggle class="nav-list">
        <router-link v-for="(page, i) in appPages" :key="i" :to="{ path: page.url, query: { utm_medium: 'web', utm_source: 'nav' } }">
          <ion-item lines="none" button data-cy="mobile-nav-drawer-links" :id="page.title + '-link'">
            <ion-label class="nav-label">
              {{ page.title }}
            </ion-label>
          </ion-item>
        </router-link>
      </ion-menu-toggle>

      <div class="list-divider"></div>

      <!-- Logged in Navigation -->
      <div class="nav-list" v-if="isLoggedIn">
        <accountModal />

        <ion-item @click="logout" lines="none" detail="false" button data-cy="mobile-nav-drawer-user-links">
          <ion-label class="nav-label"> Sign Out </ion-label>
        </ion-item>
      </div>
    </ion-content>

    <!-- Join Rewards CTA -->
    <ion-row v-if="!isLoggedIn" size="12" class="rewards-container" data-cy="rewards-container">
      <ion-col size="12" class="rewards-col">
        <ion-img alt="rewards Image" :src="require('@/assets/donatos-rewards-logo-dark.svg')" class="rewards-logo" />
      </ion-col>
      <ion-col size="12" class="rewards-text" data-cy="rewards-message">
        <span>Points, Perks and Free Pizza!</span>
      </ion-col>
      <ion-col size="10" offset="1">
        <ion-menu-toggle>
          <ion-button class="join-now" @click="() => router.push('/signup')" expand="block" data-cy="button-join-now">Join Now</ion-button>
        </ion-menu-toggle>
      </ion-col>
      <ion-col size="10" offset="1">
        <ion-menu-toggle>
          <ion-button @click="() => router.replace('/login')" expand="block" fill="outline" data-cy="button-sign-in">Sign In</ion-button>
        </ion-menu-toggle>
      </ion-col>
    </ion-row>
  </ion-menu>
</template>

<script lang="ts">
import { IonMenu, IonRow, IonToolbar, IonItem, IonHeader, IonImg, IonLabel, IonMenuToggle, IonIcon, IonContent, IonCol, IonButton } from "@ionic/vue";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { close, chevronForward } from "ionicons/icons";
import accountModal from "@/components/modals/accountModal.vue";

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();

    const selectedIndex = ref(0);
    const isLoggedIn = computed(() => store.getters.isLoggedIn);
    const account = computed(() => store.getters.getAccount);
    const firstName = computed(() => store.getters.getAccountFirstName || "Guest");
    const selectedLocation = computed(() => store.getters.getSelectedLocation);
    const isCrawler = computed(() => store.getters.isCrawler);
    const redirectToLocation = computed(() => !(selectedLocation.value || isCrawler.value));
    const logout = () => store.dispatch("setLogout");

    const appPages = computed(() => [
      { title: "Home", url: "/", show: true },
      { title: "Order", url: redirectToLocation.value ? "/location" : "/menu", show: true },
      { title: "Rewards & Deals", show: true, url: "/rewards" },
      { title: "Catering", url: "/catering", show: true },
    ]);

    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.value.findIndex((page) => page.title.toLowerCase() === path.toLowerCase());
    }

    return {
      router,
      close,
      chevronForward,
      firstName,
      isLoggedIn,
      appPages,
      selectedLocation,
      logout,
      account,
    };
  },
  components: {
    IonMenu,
    IonRow,
    IonImg,
    IonLabel,
    IonToolbar,
    IonHeader,
    IonItem,
    IonMenuToggle,
    IonContent,
    IonIcon,
    IonCol,
    IonButton,
    accountModal,
  },
});
</script>

<style scoped>
.menu-logo {
  max-height: 100px;
  max-width: 150px;
}
.rewards-col {
  padding-bottom: 0px;
}
.rewards-logo {
  max-height: 200px;
  max-width: 200px;
  display: block;
  margin: 0 auto;
}
.mobile-nav-header {
  padding-top: 25px;
  padding-bottom: 25px;
}
.nav-label {
  padding-left: 5%;
}
.list-divider {
  width: 80%;
  margin: 20px auto 15px auto;
  border-bottom: 2px solid var(--ion-color-light-shade);
  border-radius: 1px;
}
ion-item {
  --highlight-color-focused: white;
  --inner-border-width: 0;
  font-weight: bold;
  color: var(--ion-color-dark-shade);
}
ion-button {
  height: 2.5em;
  --box-shadow: none;
}
.rewards-container {
  margin-bottom: 25px;
}
.rewards-text {
  text-align: center;
  font-weight: bold;
  font-style: italic;
}
.welcome {
  font-size: 1.2em;
}
.first-name {
  color: var(--ion-color-primary);
  font-weight: bold;
}
.account-rewards {
  color: var(--ion-color-primary);
  font-size: 0.9em;
  font-weight: bold;
}
</style>
