<template>
  <img v-if="type.toUpperCase() == cardTypeEnum.visa" alt="Visa Card Logo" src="@/assets/payment-logos/visa.png" />
  <img v-else-if="type.toUpperCase() == cardTypeEnum.masterCard" alt="MasterCard Logo" src="@/assets/payment-logos/mastercard.png" />
  <img v-else-if="type.toUpperCase() == cardTypeEnum.discover" alt="Discover Logo" src="@/assets/payment-logos/discover.png" />
  <img v-else-if="type.toUpperCase() == cardTypeEnum.amex" alt="Amex Logo" src="@/assets/payment-logos/amex.png" />
</template>

<script lang="ts">
import { cardTypeEnum } from "@/enums/PaymentTypeEnum";

export default {
  props: {
    type: { required: true, type: String },
  },
  setup() {
    return {
      cardTypeEnum,
    };
  },
};
</script>

<style scoped>
img {
  width: 2.5em;
}
.extra-small {
  width: 1.5em;
}
.small {
  width: 2em;
}
.medium {
  width: 2.5;
}
.large {
  width: 3.5;
}
.extra-large {
  width: 4;
}
</style>
