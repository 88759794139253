<template>
  <ion-item-divider mode="md"></ion-item-divider>

  <ion-col v-if="canSaveCard" size="5" class="interstate-bold">
    <ion-chip v-if="card?.default" class="primary">Primary</ion-chip>
    <ion-chip v-else class="secondary" outline @click="makePrimary">Make Primary</ion-chip>
  </ion-col>

  <ion-col class="interstate-bold" :size="canSaveCard ? '5' : '8'">
    <payment-type-image :type="card?.cardType ?? 'MC'" class="small" />&nbsp;*{{ card?.lastFourDigits }}
    <div>
      <ion-text color="medium" style="font-size: 14px">Expires {{ card?.cardExpirationMonth }}/{{ card?.cardExpirationYear }}</ion-text>
    </div>
  </ion-col>

  <ion-col v-if="canDeleteCard || canRemoveCardFromOrder" size="2" class="text-right">
    <ion-button @click="deleteCard" class="icon-button">
      <ion-icon :ios="trashOutline" :md="trashOutline" slot="icon-only" />
    </ion-button>
  </ion-col>

  <ion-item-divider mode="md"></ion-item-divider>
</template>

<script lang="ts">
import { IonButton, IonChip, IonIcon, IonCol, IonText, IonItemDivider } from "@ionic/vue";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { trashOutline, addCircleOutline } from "ionicons/icons";
import PaymentTypeImage from "@/components/paymentTypeImage.vue";
import { CreditCard } from "@/models/PaymentModels";

export default defineComponent({
  props: {
    card: CreditCard,
    handleDelete: Function,
    canRemoveCardFromOrder: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["handleDelete", "handleMakePrimary", "undo"],
  setup(props, { emit }) {
    const store = useStore();
    const canSaveCard = computed(() => store.getters.isLoggedIn);
    const canDeleteCard = computed(() => store.getters.isLoggedIn && props?.card?.id !== "");

    const deleteCard = () => {
      emit("handleDelete", props.card);
    };

    const makePrimary = () => {
      emit("handleMakePrimary", props.card);
    };

    return { trashOutline, addCircleOutline, canSaveCard, canDeleteCard, deleteCard, makePrimary };
  },
  components: {
    IonIcon,
    IonButton,
    IonChip,
    IonCol,
    IonText,
    PaymentTypeImage,
    IonItemDivider,
  },
});
</script>

<style scoped>
.text-right {
  text-align: right;
}

ion-col {
  margin-top: 20px;
  margin-bottom: 20px;
}

ion-button.icon-button {
  --background: transparent;
  --box-shadow: none;
  --color: gray;
  text-transform: none;
}

ion-button#add-payment-button {
  color: var(--ion-color-primary);
  font-weight: 600;
  font-size: large;
}

ion-chip.primary {
  --color: white;
  --background: var(--ion-color-medium-shade);
}

ion-chip.secondary {
  --color: var(--ion-color-dark-shade);
}

.addPaymentColumn {
  text-align: center;
}
</style>
