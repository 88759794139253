<template>
  <ion-input
    label-placement="floating"
    :fill="fill"
    :placeholder="placeholder"
    v-model="localModelValue"
    v-on:keyup.enter="$emit('enter')"
    :type="type"
    :autocomplete="autocomplete"
    color="medium"
    class="custom"
    mode="md"
  >
      <div slot="label" class="label-color" v-if="label">{{ label }} <ion-text v-if="required" color="danger">*</ion-text></div>
  </ion-input>
</template>

<script lang="ts">
import { IonInput, IonText } from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    label: String,
    placeholder: String,
    required: Boolean,
    modelValue: { required: true },
    fill: String,
    labelColor: String,
    autocomplete: String,
    type: { type: String, default: "text" },
  },
  computed: {
    rules() {
      return [this.required ? "required" : ""];
    },
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },

  components: {
    IonInput,
    IonText,
  },
});
</script>

<style scoped>
ion-input.custom {
    --placeholder-color: #92949c;
}
.label-color {
    color: #92949c;
}
</style>
