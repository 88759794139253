<template>
  <ion-header role="navigation" aria-label="desktop navigation header" class="ion-no-border">
    <ion-toolbar mode="md" class="desktop-navigation" data-cy="desktop-nav">
      <ion-row size="12" class="ion-align-items-center" style="justify-content: space-around">
        <ion-col size="1">
          <router-link to="/" aria-label="Home Button" data-cy="desktop-button-logo" data-link="nav">
            <ion-img alt="Donatos Logo" class="menu-logo" :src="require('@/assets/logo-dark.png')" />
          </router-link>
        </ion-col>

        <router-link
          class="desktop-nav-link"
          data-cy="desktop-link-menu"
          :to="{ path: '/rewards', query: { utm_medium: 'web', utm_source: 'nav' } }"
          replace
          data-link="nav"
          >Rewards & Deals</router-link
        >
        <router-link
          class="desktop-nav-link"
          data-cy="desktop-link-menu"
          data-link="nav"
          :to="{
            path: redirectToLocation ? '/location' : '/menu',
            query: redirectToLocation ? { redirect: '/menu', utm_medium: 'web', utm_source: 'nav' } : { utm_medium: 'web', utm_source: 'nav' },
          }"
          >Menu & Order</router-link
        >
        <router-link
          class="desktop-nav-link"
          data-cy="desktop-link-locations"
          data-link="nav"
          :to="{ path: '/location', query: { utm_medium: 'web', utm_source: 'nav' } }"
          >Locations</router-link
        >
        <router-link
          class="desktop-nav-link"
          data-cy="desktop-link-rewards"
          data-link="nav"
          :to="{ path: '/catering', query: { utm_medium: 'web', utm_source: 'nav' } }"
          >Catering</router-link
        >
        <a
          class="desktop-nav-link"
          data-cy="desktop-link-franchise"
          href="https://www.donatospizzafranchise.com/?SPPC=Offline&sppccampaignid=902738&utm_source=Scorpion%20Advertising&utm_medium=cpc&utm_campaign=Offline"
          target="_blank"
          >Own a Donatos</a
        >

        <ion-col size="1">
          <ion-row class="ion-align-items-center" style="justify-content: space-between">
            <ion-col size="5" class="px-0">
              <accountModal iconBtn />
            </ion-col>
            <ion-col size="5" class="px-0">
              <cartBtn style="font-size: 30px" color="medium" data-cy="desktop-cart-icon" />
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CartBtn from "@/components/cartBtn.vue";
import { IonHeader, IonToolbar, IonImg, IonRow, IonCol } from "@ionic/vue";
import accountModal from "@/components/modals/accountModal.vue";

export default defineComponent({
  props: {
    redirectToLocation: Boolean,
  },
  components: { IonHeader, IonToolbar, CartBtn, IonRow, IonCol, IonImg, accountModal },
});
</script>

<style scoped>
.menu-logo {
  max-width: 150px;
  margin: 0 auto;
}
.desktop-navigation {
  padding: 5px 0;
  border-bottom: 4px solid var(--ion-color-primary);
}
.desktop-navigation .menu-logo {
  min-width: 100px;
  max-width: 200px;
}
.desktop-nav-link {
  font-weight: bold;
  color: var(--ion-color-dark-shade);
  text-decoration: none;
  padding: 16px 0;
}
.desktop-nav-link:hover {
  color: var(--ion-color-primary);
}
ion-col {
  max-height: 64px;
}

@media only screen and (max-width: 959px) {
  .desktop-navigation {
    display: none;
    border-bottom: 4px solid red;
  }
}
</style>
