<template>
  <ion-input
    :label-placement="stacked ? 'stacked' : 'floating'"
    fill="solid"
    placeholder="example@gmail.com"
    v-model="localModelValue"
    v-on:keyup:enter="$emit('enter')"
    autocomplete="email"
    type="email"
    inputmode="email"
    pattern="email"
    :name="name"
    color="medium"
    class="custom"
    mode="md"
  >
      <div slot="label" class="label-color">{{ label ? label : "Email Address"}} <ion-text v-if="required" color="danger">*</ion-text></div>
  </ion-input>
</template>

<script lang="ts">
import {IonInput, IonText} from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    label: String,
    required: {
      type: Boolean,
      default: true,
    },
    modelValue: String,
    name: String,
    labelColor: String,
    dense: Boolean,
    stacked: Boolean,
    mode: String,
  },

  computed: {
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
  components: {IonText, IonInput },
});
</script>

<style scoped>
ion-input.custom {
    --placeholder-color: #92949c;
}
.label-color {
    color: #92949c;
}
</style>