<template>
    <ion-input
    :label-placement="stacked ? 'stacked' : 'floating'"
    :fill="fill"
    :placeholder="placeholder"
    v-model="localModelValue"
    v-on:keyup.enter="$emit('enter')"
    :autocomplete="nameType(label)"
    maxlength="45"
    color="medium"
    :error-text="error"
    class="custom"
    mode="md"
    >
        <div slot="label" class="label-color">{{ label }} <ion-text v-if="required" color="danger">*</ion-text></div>
    </ion-input>
</template>

<script lang="ts">
import { IonInput, IonText } from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    label: String,
    placeholder: String,
    required: Boolean,
    modelValue: String,
    fill: String,
    labelColor: String,
    dense: Boolean,
    stacked: Boolean,
    mode: String,
    error: String,
    shape: String,
  },
  computed: {
    rules() {
      return [this.required ? "required" : ""];
    },
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
  methods: {
    nameType(label) {
      if (label?.toLowerCase().includes("first")) return "given-name";
      if (label?.toLowerCase().includes("last")) return "family-name";
      return "name";
    },
  },

  components: {
    IonInput,
    IonText
  },
});
</script>

<style scoped>
ion-input.custom {
    --placeholder-color: #92949c;
}
.label-color {
    color: #92949c;
}
</style>
